import React from "react";
import { FaFacebook, FaLinkedin, FaGithub } from "react-icons/fa";

export const socialIcons = [
  {
    id: 1,
    link: "https://github.com/ExoticFormula",
    icon: <FaGithub />,
  },
  {
    id: 2,
    link: "#",
    icon: <FaLinkedin />,
  },
  {
    id: 3,
    link: "#",
    icon: <FaFacebook />,
  },
];
